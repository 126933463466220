import { type FetchError } from 'ofetch';
import { defineStore } from 'pinia';
import { stringify } from 'qs';
import { useAdvertisingStore } from './advertising';
import { useClientStore } from './clients';
import { useDocumentsStore } from './documents';
import { useNotificationsStore } from './notifications';
import { usePortfoliosStore } from './portfolios';
import { useQuestionnairesStore } from './questionnaires';
import { useSecurityStore } from './security';
import {
  ClientType,
  CountryOfRegistration,
  RegistrationStatus,
  type ChangeRequestProperty,
  type Client,
  type LoginInfo,
} from '~/models/clients';
import { Product, type AuthErrorCode, type LinkModel, type Token } from '~/models/arbitrary';
import { useApiFetch } from '~/composables/useApiFetch';

export const SESSION = 'user';
export const SESSION_REFRESHING = 'user_refreshing';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isLoggedIn: false,
    user: {} as Client,
    token: {} as Token,
    loginError: '' as AuthErrorCode,
    logoutTimeout: null as number | null,
    isIdleLogoutSetup: false,
  }),
  getters: {
    initials: (state) => {
      if (state.user.FirstName && state.user.ClientType === ClientType.Corporate) {
        return state.user.FirstName[0];
      }

      if (!state.user.FirstName || !state.user.LastName) {
        return '';
      }

      return `${state.user.FirstName[0]}${state.user.LastName[0]}`;
    },
    isLocked: (state) => {
      return state.user.IsLocked;
    },
    canInvest: (state) => {
      return (
        state.user.RegistrationStatus >= RegistrationStatus.ClientCompleted &&
        !!state.user.IsRegistrationApproved
      );
    },
    fullName: (state) => {
      let name = '';

      if (state.user.Title && state.user.Title.length > 0) {
        name += state.user.Title;
      }

      if (state.user.FirstName && state.user.FirstName.length > 0) {
        if (name.length > 0) {
          name += ' ';
        }

        name += state.user.FirstName;
      }

      if (state.user.LastName && state.user.LastName.length > 0) {
        if (name.length > 0) {
          name += ' ';
        }

        name += state.user.LastName;
      }

      if (state.user.Suffix && state.user.Suffix.length > 0) {
        if (name.length > 0) {
          name += ', ';
        }
        name += state.user.Suffix;
      }

      return name;
    },
    changeRequestData: (state) => (property: ChangeRequestProperty) => {
      return state.user.PendingChangeRequests.find(
        (changeRequest) => changeRequest.Item1 === property,
      );
    },
  },
  actions: {
    async login({
      email,
      password,
      useSecondaryProvider,
    }: {
      email: string;
      password: string;
      useSecondaryProvider: boolean;
    }) {
      this.loginError = '';

      try {
        const securityStore = useSecurityStore();
        const clientStore = useClientStore();

        const token = await securityStore.getToken({ email, password, useSecondaryProvider });
        this._setToken({ token });
        this.isLoggedIn = true;
        this.user = await clientStore.getByEmail({ email });

        this.persist();
        this._afterLoginHandler();
      } catch (error) {
        this.loginError = error?.error ?? (error as FetchError).data.error;
      }
    },
    async loginUsing2fa({ email, smsCode }: { email: string; smsCode: string }) {
      const securityStore = useSecurityStore();
      const clientStore = useClientStore();

      const token = await securityStore.twoFactorSingIn({ email, smsCode });
      this._setToken({ token });
      this.isLoggedIn = true;
      this.user = await clientStore.getByEmail({ email });

      this.persist();
      this._afterLoginHandler();
    },
    async loginUsingEmail({
      email,
      code,
      id,
    }: {
      email: string;
      code: string;
      id: string | undefined;
    }) {
      const securityStore = useSecurityStore();
      const clientStore = useClientStore();

      const token = await securityStore.emailSignIn({ email, code, id, isMobileUpload: true });
      this._setToken({ token });
      this.isLoggedIn = true;
      if (id) {
        this.user = await clientStore.getById({ id });
      } else {
        this.user = await clientStore.getByEmail({ email });
      }

      this.persist();
      this._afterLoginHandler();
    },
    async loginUsingImpersonation({ code }: { code: string }) {
      const securityStore = useSecurityStore();
      const clientStore = useClientStore();

      const { Identifier, ...token } = await securityStore.impersonationSignIn({ code });
      this._setToken({ token });
      this.isLoggedIn = true;
      this.user = await clientStore.getById({ id: Identifier! });

      this.persist();
      this._afterLoginHandler();
    },
    async logout() {
      const securityStore = useSecurityStore();

      try {
        if (this.token.AccessToken) {
          await securityStore.logout();
        }
      } catch (error) {}

      this._afterLogout();
    },
    logoutRemote() {
      // TODO(martin_obadal): what goes here? 🤔
    },
    async logoutAllDevices() {
      const securityStore = useSecurityStore();

      try {
        if (this.token.AccessToken) {
          await securityStore.logoutAllDevices();
        }
      } catch (error) {}

      this._afterLogout();
    },
    async refreshUser() {
      const clientStore = useClientStore();

      this.user = await clientStore.getById({ id: this.user.Id });
    },
    async register({ email, password }: { email: string; password: string }) {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const fetch = useApiFetch();

      const clientsStore = useClientStore();

      await fetch<Client>('/clients/register', {
        method: 'POST',
        body: {
          Email: email,
          Password: password,
          GalleryPreferredLanguage: locale.value,
          CountryOfRegistration: CountryOfRegistration.CZ,
        } as Partial<Client>,
      });
      await this.login({ email, password, useSecondaryProvider: false });

      clientsStore.update({
        ConfirmationStatements: true,
        GalleryMarketingEmailEnabled: true,
        PortuProductInfo: true,
        // TODO(martin_obadal): add RegistrationReferenceCode & RegistrationNotes
      });
    },
    async resendVerificationEmail() {
      const fetch = useApiFetch();

      return await fetch<{ Id: string }>(`/email/${this.user.Id}/verification`, { method: 'POST' });
    },
    async getExternalLoginUrl({ redirectUrl, product }: { redirectUrl: string; product: Product }) {
      const fetch = useApiFetch();

      return await fetch<LinkModel>(
        `/clients/${this.user.Id}/loginurl${stringify(
          { redirectUri: redirectUrl, product },
          { addQueryPrefix: true },
        )}`,
      );
    },
    createVisibilityChangedEvent() {
      window.addEventListener('visibilitychange', async () => {
        if (document.visibilityState === 'visible') {
          if (!this.isLoggedIn) {
            await this.hydrate();
          }
        }
      });
    },
    persist() {
      localStorage.setItem(SESSION, JSON.stringify({ user: this.user, token: this.token }));
    },
    async hydrate() {
      const notificationsStore = useNotificationsStore();
      const session = JSON.parse(localStorage.getItem(SESSION) || 'null') as Pick<
        typeof this,
        'user' | 'token'
      > | null;

      const { getUnixTime } = useDateFns();
      if (!session || getUnixTime(Date.now()) - session.token.ExpiresAt >= 0) {
        await this.logout();

        return;
      }

      const clientStore = useClientStore();

      this._setToken({ token: session.token });
      this.isLoggedIn = true;
      this.user = await clientStore.getById({ id: session.user.Id });

      notificationsStore._hydrate();
    },
    async getLoginHistory() {
      const fetch = useApiFetch();

      return await fetch<LoginInfo[]>(`/clients/${this.user.Id}/loginhistory`);
    },
    _afterLoginHandler() {
      this._updateAfterLoginClient();
    },
    async _updateAfterLoginClient() {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const clientStore = useClientStore();

      if (!this.user.GalleryPreferredLanguage || this.user.GalleryPreferredLanguage.length === 0) {
        await clientStore.update({
          GalleryPreferredLanguage: locale.value,
        });
      }
    },
    _setToken({ token }: { token: Token }) {
      const { getUnixTime } = useDateFns();

      this.token = {
        ...token,
        ExpiresAt: getUnixTime(Date.now()) + token.ExpiresIn,
      };
    },
    _afterLogout() {
      const clientStore = useClientStore();
      const documentsCache = useDocumentsStore();
      const portfoliosStore = usePortfoliosStore();
      const questionnairesStore = useQuestionnairesStore();
      const notificationsStore = useNotificationsStore();
      const advertisingStore = useAdvertisingStore();
      const vouchersStore = useVouchersStore();

      this.isLoggedIn = false;
      this.user = {} as Client;
      this.token = {} as Token;

      localStorage.removeItem(SESSION);

      clientStore._resetCache();
      documentsCache._resetClientCache();
      portfoliosStore._resetCache();
      questionnairesStore._resetCache();
      notificationsStore._resetCache();
      advertisingStore._resetCache();
      vouchersStore._resetCache();
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
