import { default as _91_46_46_46not_45found_93tQXeTdXzmeMeta } from "C:/FE02/_work/222/s/Gallery/pages/[...not-found].vue?macro=true";
import { default as abouthdTtnvH8l7Meta } from "C:/FE02/_work/222/s/Gallery/pages/about.vue?macro=true";
import { default as account_45created7QdK64bfwHMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/account-created.vue?macro=true";
import { default as additional_45informationyTcyn2YhfYMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/additional-information.vue?macro=true";
import { default as addresses6TNTgSPhbCMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/addresses.vue?macro=true";
import { default as amendmentNSt1X87XeAMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/amendment.vue?macro=true";
import { default as indexKoXfQEHF5NMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/bank-account/index.vue?macro=true";
import { default as requestedtWV2RXERehMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/bank-account/requested.vue?macro=true";
import { default as contract_45signingAM2ZJYObKUMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/contract-signing.vue?macro=true";
import { default as document_45updateW0zGQcfCuqMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/document-update.vue?macro=true";
import { default as documents_45checkJRM26JHM6BMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/documents-check.vue?macro=true";
import { default as indexbdwruzVw6LMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/identity-verification/index.vue?macro=true";
import { default as requestedPgMrHmDyARMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/identity-verification/requested.vue?macro=true";
import { default as upload_45documentsY4BhEqiQxAMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/identity-verification/upload-documents.vue?macro=true";
import { default as personal_45dataQQ7ZRVwVaFMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/personal-data.vue?macro=true";
import { default as phone_45number_45verificationytih2qOSCpMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/phone-number-verification.vue?macro=true";
import { default as indexfNYQlVXgp5Meta } from "C:/FE02/_work/222/s/Gallery/pages/account/politically-exposed-person/index.vue?macro=true";
import { default as requestedyB2HebTIC9Meta } from "C:/FE02/_work/222/s/Gallery/pages/account/politically-exposed-person/requested.vue?macro=true";
import { default as questionnaireC8WvGuAF2MMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/questionnaire.vue?macro=true";
import { default as indexoTocu4bS8bMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/source-of-income-statement/index.vue?macro=true";
import { default as requestedpxW1AObVSbMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/source-of-income-statement/requested.vue?macro=true";
import { default as verificationKAipunm0YeMeta } from "C:/FE02/_work/222/s/Gallery/pages/account/verification.vue?macro=true";
import { default as annual_45reportsx9HG53eHzJMeta } from "C:/FE02/_work/222/s/Gallery/pages/annual-reports.vue?macro=true";
import { default as bankidx7J5xKdFuBMeta } from "C:/FE02/_work/222/s/Gallery/pages/bankid.vue?macro=true";
import { default as contactiyvZ5l2jizMeta } from "C:/FE02/_work/222/s/Gallery/pages/contact.vue?macro=true";
import { default as contractual_45documentationI4jrhig53LMeta } from "C:/FE02/_work/222/s/Gallery/pages/contractual-documentation.vue?macro=true";
import { default as _91id_93AJGpRiCEMEMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/[id].vue?macro=true";
import { default as _91id_93QG8hiKZzwOMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/advertisement/[id].vue?macro=true";
import { default as newqiYq52P0StMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/advertisement/new.vue?macro=true";
import { default as indexotqYWYzZi0Meta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/index.vue?macro=true";
import { default as my_45advertisementsjqxfMEpeUXMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/my-advertisements.vue?macro=true";
import { default as termsuhVfXs3yhuMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/terms.vue?macro=true";
import { default as _91id_936EZDSaJdUsMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/trades/[id].vue?macro=true";
import { default as indexza1ivdJsADMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/trades/index.vue?macro=true";
import { default as newq7oUCNpe2OMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/trades/new.vue?macro=true";
import { default as advertising_45spacexRU3JrJLcwMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space.vue?macro=true";
import { default as depositPtIBkmKztgMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/deposit.vue?macro=true";
import { default as indexZY1wDQLiveMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/index.vue?macro=true";
import { default as purchase_45instructionsSN10IpM0LuMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/purchase-instructions.vue?macro=true";
import { default as transactions_45historyZHoEhgH6JeMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/transactions-history.vue?macro=true";
import { default as confirmationvjAlOPZA59Meta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/withdrawal/confirmation.vue?macro=true";
import { default as indexgNwKPBthRGMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/withdrawal/index.vue?macro=true";
import { default as financep14rGQoy3IMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/finance.vue?macro=true";
import { default as indexGqBnZKGa9tMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/gifts-and-benefits/index.vue?macro=true";
import { default as unique_45exhibition_45alfons_45muchaKUkM1OkFBmMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/gifts-and-benefits/unique-exhibition-alfons-mucha.vue?macro=true";
import { default as vouchers8L0G8kGKdSMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/gifts-and-benefits/vouchers.vue?macro=true";
import { default as gifts_45and_45benefitsg90FBdCR7FMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/gifts-and-benefits.vue?macro=true";
import { default as indexrCgtcbS1XqMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/index.vue?macro=true";
import { default as indexDPk1PmH86WMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/index.vue?macro=true";
import { default as _91slug_93lntxLhMf2YMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/investments-offer/[slug].vue?macro=true";
import { default as indexnb0MryFisQMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/investments-offer/index.vue?macro=true";
import { default as investments_45offerD2MTHdnxTFMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/investments-offer.vue?macro=true";
import { default as my_45investmentsH8BNDzVXpZMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/my-investments.vue?macro=true";
import { default as votingFw7Je9RZaQMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/voting.vue?macro=true";
import { default as investmentsd8FJMuEG5cMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/investments.vue?macro=true";
import { default as add_45accountCEVox3HlXPMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/add-account.vue?macro=true";
import { default as additional_45settingsUMIjlufALNMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/additional-settings.vue?macro=true";
import { default as indexrpm8nhsrv0Meta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/bank-accounts/add/index.vue?macro=true";
import { default as requestedmmmKY3PbwYMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/bank-accounts/add/requested.vue?macro=true";
import { default as upload_45documentN4BZfpTPbIMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/bank-accounts/add/upload-document.vue?macro=true";
import { default as indexsa8YBA0qo1Meta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/bank-accounts/index.vue?macro=true";
import { default as delete_45account5uygiNMkGpMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/delete-account.vue?macro=true";
import { default as indexPU9IIjqpiGMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/document-update/index.vue?macro=true";
import { default as requestedKkxjSPBhmoMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/document-update/requested.vue?macro=true";
import { default as edit_45personal_45informationTN3lUa5gmoMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/edit-personal-information.vue?macro=true";
import { default as email_45changet9IpSdwQTyMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/email-change.vue?macro=true";
import { default as email_45preferenceswhCOo4vYxDMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/email-preferences.vue?macro=true";
import { default as czech_45pointoMdzBtH43bMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/identity-verification/czech-point.vue?macro=true";
import { default as indexjrIPQgA1oVMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/identity-verification/index.vue?macro=true";
import { default as indexF3vAibt6WoMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/index.vue?macro=true";
import { default as mailing_45address_45changeVhgfGmWdllMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/mailing-address-change.vue?macro=true";
import { default as indexNr3uwDyQyzMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/name-change/index.vue?macro=true";
import { default as requested0FNovJ1EoDMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/name-change/requested.vue?macro=true";
import { default as upload_45documentsdKyYGv63ObMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/name-change/upload-documents.vue?macro=true";
import { default as indexaQPzrCEeZ1Meta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/permanent-address-change/index.vue?macro=true";
import { default as requested75AiATTBN4Meta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/permanent-address-change/requested.vue?macro=true";
import { default as upload_45documents9o7cM4HNTUMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/permanent-address-change/upload-documents.vue?macro=true";
import { default as additional_45informationnF3iC9S0w1Meta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/additional-information.vue?macro=true";
import { default as indexKZPG7kVuBTMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/index.vue?macro=true";
import { default as indexzkilT4fZ9RMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/index.vue?macro=true";
import { default as requestedByOX7QJVAYMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/requested.vue?macro=true";
import { default as indexVSHXmSf0muMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/index.vue?macro=true";
import { default as requested7XejZv2fKzMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/requested.vue?macro=true";
import { default as indexQrfe2syal0Meta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/index.vue?macro=true";
import { default as requestedAseBJPCkiUMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/requested.vue?macro=true";
import { default as personal_45data_45confirmationX1URU47hmCMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation.vue?macro=true";
import { default as phone_45changeSWwqn2fdHwMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/phone-change.vue?macro=true";
import { default as indexEAQ2hbPKAvMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/security/index.vue?macro=true";
import { default as password_45changeqSqFP3cEOaMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/security/password-change.vue?macro=true";
import { default as documents3HiAS8MQvIMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/statements-and-documents/documents.vue?macro=true";
import { default as indexKYcq01F45TMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/statements-and-documents/index.vue?macro=true";
import { default as statements_45and_45confirmationsijKu9ycHPtMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/statements-and-documents/statements-and-confirmations.vue?macro=true";
import { default as profileJvJJaCoDThMeta } from "C:/FE02/_work/222/s/Gallery/pages/dashboard/profile.vue?macro=true";
import { default as elementOtEKiAeLQGMeta } from "C:/FE02/_work/222/s/Gallery/pages/element.vue?macro=true";
import { default as email_45verificationg3J2AXBwbDMeta } from "C:/FE02/_work/222/s/Gallery/pages/external/email-verification.vue?macro=true";
import { default as impersonationwYypieJAicMeta } from "C:/FE02/_work/222/s/Gallery/pages/external/impersonation.vue?macro=true";
import { default as loginNWgcWuRhSsMeta } from "C:/FE02/_work/222/s/Gallery/pages/external/login.vue?macro=true";
import { default as password_45reset3onpIFcn98Meta } from "C:/FE02/_work/222/s/Gallery/pages/external/password-reset.vue?macro=true";
import { default as _91slug_93P9FmVHK7vWMeta } from "C:/FE02/_work/222/s/Gallery/pages/faq/[slug].vue?macro=true";
import { default as index0At7MJA4tmMeta } from "C:/FE02/_work/222/s/Gallery/pages/faq/index.vue?macro=true";
import { default as faqqdUhnRvVpXMeta } from "C:/FE02/_work/222/s/Gallery/pages/faq.vue?macro=true";
import { default as forgotten_45passwords33Bj3xrqpMeta } from "C:/FE02/_work/222/s/Gallery/pages/forgotten-password.vue?macro=true";
import { default as how_45does_45it_45workazoUAlaiDfMeta } from "C:/FE02/_work/222/s/Gallery/pages/how-does-it-work.vue?macro=true";
import { default as index6DIGJRn5xrMeta } from "C:/FE02/_work/222/s/Gallery/pages/index.vue?macro=true";
import { default as _91slug_93Q9uOkcSWu0Meta } from "C:/FE02/_work/222/s/Gallery/pages/investments-offer/[slug].vue?macro=true";
import { default as indexRwbWlrBFFJMeta } from "C:/FE02/_work/222/s/Gallery/pages/investments-offer/index.vue?macro=true";
import { default as login14D0mLbf75Meta } from "C:/FE02/_work/222/s/Gallery/pages/login.vue?macro=true";
import { default as obligatory_45published_45informationSC0RRoVckIMeta } from "C:/FE02/_work/222/s/Gallery/pages/obligatory-published-information.vue?macro=true";
import { default as privacy_45settingsZgc4g9MXhTMeta } from "C:/FE02/_work/222/s/Gallery/pages/privacy-settings.vue?macro=true";
import { default as registrationyVmAt6lJX6Meta } from "C:/FE02/_work/222/s/Gallery/pages/registration.vue?macro=true";
import { default as securityEJy3YAS70QMeta } from "C:/FE02/_work/222/s/Gallery/pages/security.vue?macro=true";
export default [
  {
    name: "notfound___cs___default",
    path: "/:notfound(.*)*",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/[...not-found].vue").then(m => m.default || m)
  },
  {
    name: "notfound___cs",
    path: "/cs/:notfound(.*)*",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/[...not-found].vue").then(m => m.default || m)
  },
  {
    name: "about___cs___default",
    path: "/o-nas",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___cs",
    path: "/cs/o-nas",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "account-account-created___cs___default",
    path: "/ucet/ucet-vytvoren",
    meta: account_45created7QdK64bfwHMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/account-created.vue").then(m => m.default || m)
  },
  {
    name: "account-account-created___cs",
    path: "/cs/ucet/ucet-vytvoren",
    meta: account_45created7QdK64bfwHMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/account-created.vue").then(m => m.default || m)
  },
  {
    name: "account-additional-information___cs___default",
    path: "/ucet/dalsi-informace",
    meta: additional_45informationyTcyn2YhfYMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "account-additional-information___cs",
    path: "/cs/ucet/dalsi-informace",
    meta: additional_45informationyTcyn2YhfYMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___cs___default",
    path: "/ucet/adresy",
    meta: addresses6TNTgSPhbCMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___cs",
    path: "/cs/ucet/adresy",
    meta: addresses6TNTgSPhbCMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-amendment___cs___default",
    path: "/ucet/dodatek",
    meta: amendmentNSt1X87XeAMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/amendment.vue").then(m => m.default || m)
  },
  {
    name: "account-amendment___cs",
    path: "/cs/ucet/dodatek",
    meta: amendmentNSt1X87XeAMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/amendment.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account___cs___default",
    path: "/ucet/bankovni-ucet",
    meta: indexKoXfQEHF5NMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/bank-account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account___cs",
    path: "/cs/ucet/bankovni-ucet",
    meta: indexKoXfQEHF5NMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/bank-account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account-requested___cs___default",
    path: "/ucet/bankovni-ucet/overeni",
    meta: requestedtWV2RXERehMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/bank-account/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account-requested___cs",
    path: "/cs/ucet/bankovni-ucet/overeni",
    meta: requestedtWV2RXERehMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/bank-account/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-contract-signing___cs___default",
    path: "/ucet/podpis-smlouvy",
    meta: contract_45signingAM2ZJYObKUMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/contract-signing.vue").then(m => m.default || m)
  },
  {
    name: "account-contract-signing___cs",
    path: "/cs/ucet/podpis-smlouvy",
    meta: contract_45signingAM2ZJYObKUMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/contract-signing.vue").then(m => m.default || m)
  },
  {
    name: "account-document-update___cs___default",
    path: "/ucet/aktualizace-dokladu",
    meta: document_45updateW0zGQcfCuqMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/document-update.vue").then(m => m.default || m)
  },
  {
    name: "account-document-update___cs",
    path: "/cs/ucet/aktualizace-dokladu",
    meta: document_45updateW0zGQcfCuqMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/document-update.vue").then(m => m.default || m)
  },
  {
    name: "account-documents-check___cs___default",
    path: "/ucet/kontrola-dokladu",
    meta: documents_45checkJRM26JHM6BMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/documents-check.vue").then(m => m.default || m)
  },
  {
    name: "account-documents-check___cs",
    path: "/cs/ucet/kontrola-dokladu",
    meta: documents_45checkJRM26JHM6BMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/documents-check.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification___cs___default",
    path: "/ucet/overeni-identity",
    meta: indexbdwruzVw6LMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification___cs",
    path: "/cs/ucet/overeni-identity",
    meta: indexbdwruzVw6LMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-requested___cs___default",
    path: "/ucet/overeni-identity/overeni",
    meta: requestedPgMrHmDyARMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/identity-verification/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-requested___cs",
    path: "/cs/ucet/overeni-identity/overeni",
    meta: requestedPgMrHmDyARMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/identity-verification/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-upload-documents___cs___default",
    path: "/ucet/overeni-identity/nahrat-doklady",
    meta: upload_45documentsY4BhEqiQxAMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/identity-verification/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-upload-documents___cs",
    path: "/cs/ucet/overeni-identity/nahrat-doklady",
    meta: upload_45documentsY4BhEqiQxAMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/identity-verification/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-data___cs___default",
    path: "/ucet/osobni-udaje",
    meta: personal_45dataQQ7ZRVwVaFMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/personal-data.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-data___cs",
    path: "/cs/ucet/osobni-udaje",
    meta: personal_45dataQQ7ZRVwVaFMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/personal-data.vue").then(m => m.default || m)
  },
  {
    name: "account-phone-number-verification___cs___default",
    path: "/ucet/overeni-telefonniho-cisla",
    meta: phone_45number_45verificationytih2qOSCpMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/phone-number-verification.vue").then(m => m.default || m)
  },
  {
    name: "account-phone-number-verification___cs",
    path: "/cs/ucet/overeni-telefonniho-cisla",
    meta: phone_45number_45verificationytih2qOSCpMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/phone-number-verification.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person___cs___default",
    path: "/ucet/politicky-exponovana-osoba",
    meta: indexfNYQlVXgp5Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person___cs",
    path: "/cs/ucet/politicky-exponovana-osoba",
    meta: indexfNYQlVXgp5Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person-requested___cs___default",
    path: "/ucet/politicky-exponovana-osoba/overeni",
    meta: requestedyB2HebTIC9Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person-requested___cs",
    path: "/cs/ucet/politicky-exponovana-osoba/overeni",
    meta: requestedyB2HebTIC9Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-questionnaire___cs___default",
    path: "/ucet/dotaznik",
    meta: questionnaireC8WvGuAF2MMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/questionnaire.vue").then(m => m.default || m)
  },
  {
    name: "account-questionnaire___cs",
    path: "/cs/ucet/dotaznik",
    meta: questionnaireC8WvGuAF2MMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/questionnaire.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement___cs___default",
    path: "/ucet/potvrzeni-zdroje-prijmu",
    meta: indexoTocu4bS8bMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement___cs",
    path: "/cs/ucet/potvrzeni-zdroje-prijmu",
    meta: indexoTocu4bS8bMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement-requested___cs___default",
    path: "/ucet/potvrzeni-zdroje-prijmu/overeni",
    meta: requestedpxW1AObVSbMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/source-of-income-statement/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement-requested___cs",
    path: "/cs/ucet/potvrzeni-zdroje-prijmu/overeni",
    meta: requestedpxW1AObVSbMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/source-of-income-statement/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-verification___cs___default",
    path: "/ucet/verifikace",
    meta: verificationKAipunm0YeMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/verification.vue").then(m => m.default || m)
  },
  {
    name: "account-verification___cs",
    path: "/cs/ucet/verifikace",
    meta: verificationKAipunm0YeMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/account/verification.vue").then(m => m.default || m)
  },
  {
    name: "annual-reports___cs___default",
    path: "/vyrocni-zpravy",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/annual-reports.vue").then(m => m.default || m)
  },
  {
    name: "annual-reports___cs",
    path: "/cs/vyrocni-zpravy",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/annual-reports.vue").then(m => m.default || m)
  },
  {
    name: "bankid___cs___default",
    path: "/bankid",
    meta: bankidx7J5xKdFuBMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/bankid.vue").then(m => m.default || m)
  },
  {
    name: "bankid___cs",
    path: "/cs/bankid",
    meta: bankidx7J5xKdFuBMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/bankid.vue").then(m => m.default || m)
  },
  {
    name: "contact___cs___default",
    path: "/kontakt",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___cs",
    path: "/cs/kontakt",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contractual-documentation___cs___default",
    path: "/smluvni-dokumentace",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/contractual-documentation.vue").then(m => m.default || m)
  },
  {
    name: "contractual-documentation___cs",
    path: "/cs/smluvni-dokumentace",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/contractual-documentation.vue").then(m => m.default || m)
  },
  {
    name: advertising_45spacexRU3JrJLcwMeta?.name,
    path: "/souhrn/inzertni-plocha",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-advertising-space-id___cs___default",
    path: ":id()",
    meta: _91id_93AJGpRiCEMEMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-id___cs___default",
    path: "inzerat/:id()",
    meta: _91id_93QG8hiKZzwOMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/advertisement/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-new___cs___default",
    path: "inzerat/novy",
    meta: newqiYq52P0StMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/advertisement/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space___cs___default",
    path: "/souhrn/inzertni-plocha",
    meta: indexotqYWYzZi0Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-my-advertisements___cs___default",
    path: "moje-inzeraty",
    meta: my_45advertisementsjqxfMEpeUXMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/my-advertisements.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-terms___cs___default",
    path: "podminky",
    meta: termsuhVfXs3yhuMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/terms.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-id___cs___default",
    path: "pokyny-k-prevodu/:id()",
    meta: _91id_936EZDSaJdUsMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/trades/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades___cs___default",
    path: "pokyny-k-prevodu",
    meta: indexza1ivdJsADMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/trades/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-new___cs___default",
    path: "pokyny-k-prevodu/novy",
    meta: newq7oUCNpe2OMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/trades/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: advertising_45spacexRU3JrJLcwMeta?.name,
    path: "/cs/souhrn/inzertni-plocha",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-advertising-space-id___cs",
    path: ":id()",
    meta: _91id_93AJGpRiCEMEMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-id___cs",
    path: "inzerat/:id()",
    meta: _91id_93QG8hiKZzwOMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/advertisement/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-new___cs",
    path: "inzerat/novy",
    meta: newqiYq52P0StMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/advertisement/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space___cs",
    path: "/cs/souhrn/inzertni-plocha",
    meta: indexotqYWYzZi0Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-my-advertisements___cs",
    path: "moje-inzeraty",
    meta: my_45advertisementsjqxfMEpeUXMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/my-advertisements.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-terms___cs",
    path: "podminky",
    meta: termsuhVfXs3yhuMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/terms.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-id___cs",
    path: "pokyny-k-prevodu/:id()",
    meta: _91id_936EZDSaJdUsMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/trades/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades___cs",
    path: "pokyny-k-prevodu",
    meta: indexza1ivdJsADMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/trades/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-new___cs",
    path: "pokyny-k-prevodu/novy",
    meta: newq7oUCNpe2OMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/advertising-space/trades/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: financep14rGQoy3IMeta?.name,
    path: "/souhrn/penize",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/finance.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-finance-deposit___cs___default",
    path: "vklad",
    meta: depositPtIBkmKztgMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/deposit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance___cs___default",
    path: "/souhrn/penize",
    meta: indexZY1wDQLiveMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-purchase-instructions___cs___default",
    path: "pokyny",
    meta: purchase_45instructionsSN10IpM0LuMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/purchase-instructions.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-transactions-history___cs___default",
    path: "transakcni-historie",
    meta: transactions_45historyZHoEhgH6JeMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/transactions-history.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal-confirmation___cs___default",
    path: "vyber/potvrzeni",
    meta: confirmationvjAlOPZA59Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/withdrawal/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal___cs___default",
    path: "vyber",
    meta: indexgNwKPBthRGMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/withdrawal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: financep14rGQoy3IMeta?.name,
    path: "/cs/souhrn/penize",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/finance.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-finance-deposit___cs",
    path: "vklad",
    meta: depositPtIBkmKztgMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/deposit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance___cs",
    path: "/cs/souhrn/penize",
    meta: indexZY1wDQLiveMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-purchase-instructions___cs",
    path: "pokyny",
    meta: purchase_45instructionsSN10IpM0LuMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/purchase-instructions.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-transactions-history___cs",
    path: "transakcni-historie",
    meta: transactions_45historyZHoEhgH6JeMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/transactions-history.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal-confirmation___cs",
    path: "vyber/potvrzeni",
    meta: confirmationvjAlOPZA59Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/withdrawal/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal___cs",
    path: "vyber",
    meta: indexgNwKPBthRGMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/finance/withdrawal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: gifts_45and_45benefitsg90FBdCR7FMeta?.name,
    path: "/souhrn/darky-a-vyhody",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/gifts-and-benefits.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-gifts-and-benefits___cs___default",
    path: "",
    meta: indexGqBnZKGa9tMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/gifts-and-benefits/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-unique-exhibition-alfons-mucha___cs___default",
    path: "unikatni-vystava-alfons-mucha",
    meta: unique_45exhibition_45alfons_45muchaKUkM1OkFBmMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/gifts-and-benefits/unique-exhibition-alfons-mucha.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-vouchers___cs___default",
    path: "darkove-poukazy",
    meta: vouchers8L0G8kGKdSMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/gifts-and-benefits/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: gifts_45and_45benefitsg90FBdCR7FMeta?.name,
    path: "/cs/souhrn/darky-a-vyhody",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/gifts-and-benefits.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-gifts-and-benefits___cs",
    path: "",
    meta: indexGqBnZKGa9tMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/gifts-and-benefits/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-unique-exhibition-alfons-mucha___cs",
    path: "unikatni-vystava-alfons-mucha",
    meta: unique_45exhibition_45alfons_45muchaKUkM1OkFBmMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/gifts-and-benefits/unique-exhibition-alfons-mucha.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-vouchers___cs",
    path: "darkove-poukazy",
    meta: vouchers8L0G8kGKdSMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/gifts-and-benefits/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard___cs___default",
    path: "/souhrn",
    meta: indexrCgtcbS1XqMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___cs",
    path: "/cs/souhrn",
    meta: indexrCgtcbS1XqMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: investmentsd8FJMuEG5cMeta?.name,
    path: "/souhrn/investice",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/investments.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments___cs___default",
    path: "",
    meta: indexDPk1PmH86WMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/index.vue").then(m => m.default || m)
  },
  {
    name: investments_45offerD2MTHdnxTFMeta?.name,
    path: "nabidka-investic",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/investments-offer.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments-investments-offer-slug___cs___default",
    path: "/souhrn/investice/:slug()",
    meta: _91slug_93lntxLhMf2YMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-investments-offer___cs___default",
    path: "/souhrn/investice/nabidka-investic",
    meta: indexnb0MryFisQMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/investments-offer/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-investments-my-investments___cs___default",
    path: "moje-investice",
    meta: my_45investmentsH8BNDzVXpZMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/my-investments.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-voting___cs___default",
    path: "hlasovani",
    meta: votingFw7Je9RZaQMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/voting.vue").then(m => m.default || m)
  }
]
  },
  {
    name: investmentsd8FJMuEG5cMeta?.name,
    path: "/cs/souhrn/investice",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/investments.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments___cs",
    path: "",
    meta: indexDPk1PmH86WMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/index.vue").then(m => m.default || m)
  },
  {
    name: investments_45offerD2MTHdnxTFMeta?.name,
    path: "nabidka-investic",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/investments-offer.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments-investments-offer-slug___cs",
    path: "/cs/souhrn/investice/:slug()",
    meta: _91slug_93lntxLhMf2YMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-investments-offer___cs",
    path: "/cs/souhrn/investice/nabidka-investic",
    meta: indexnb0MryFisQMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/investments-offer/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-investments-my-investments___cs",
    path: "moje-investice",
    meta: my_45investmentsH8BNDzVXpZMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/my-investments.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-voting___cs",
    path: "hlasovani",
    meta: votingFw7Je9RZaQMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/investments/voting.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileJvJJaCoDThMeta?.name,
    path: "/dashboard/profile",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-add-account___cs___default",
    path: "/souhrn/profil/pridat-ucet",
    meta: add_45accountCEVox3HlXPMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/add-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-additional-settings___cs___default",
    path: "/souhrn/profil/dalsi-nastaveni",
    meta: additional_45settingsUMIjlufALNMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/additional-settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add___cs___default",
    path: "/souhrn/profil/bankovni-ucty/pridat",
    meta: indexrpm8nhsrv0Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/bank-accounts/add/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-requested___cs___default",
    path: "/souhrn/profil/bankovni-ucty/pridat/overeni",
    meta: requestedmmmKY3PbwYMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/bank-accounts/add/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-upload-document___cs___default",
    path: "/souhrn/profil/bankovni-ucty/pridat/nahrat-doklad",
    meta: upload_45documentN4BZfpTPbIMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/bank-accounts/add/upload-document.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts___cs___default",
    path: "/souhrn/profil/bankovni-ucty",
    meta: indexsa8YBA0qo1Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/bank-accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-delete-account___cs___default",
    path: "/souhrn/profil/zruseni-uctu",
    meta: delete_45account5uygiNMkGpMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/delete-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update___cs___default",
    path: "/souhrn/profil/aktualizace-dokladu",
    meta: indexPU9IIjqpiGMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/document-update/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update-requested___cs___default",
    path: "/souhrn/profil/aktualizace-dokladu/overeni",
    meta: requestedKkxjSPBhmoMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/document-update/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-edit-personal-information___cs___default",
    path: "/souhrn/profil/uprava-osobnich-udaju",
    meta: edit_45personal_45informationTN3lUa5gmoMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/edit-personal-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-change___cs___default",
    path: "/souhrn/profil/zmena-e-mailu",
    meta: email_45changet9IpSdwQTyMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/email-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-preferences___cs___default",
    path: "/souhrn/profil/e-mailova-sdeleni",
    meta: email_45preferenceswhCOo4vYxDMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/email-preferences.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification-czech-point___cs___default",
    path: "/souhrn/profil/overeni-totoznosti/czech-point",
    meta: czech_45pointoMdzBtH43bMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/identity-verification/czech-point.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification___cs___default",
    path: "/souhrn/profil/overeni-totoznosti",
    meta: indexjrIPQgA1oVMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___cs___default",
    path: "/souhrn/profil",
    meta: indexF3vAibt6WoMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-mailing-address-change___cs___default",
    path: "/souhrn/profil/zmena-korespondencni-adresy",
    meta: mailing_45address_45changeVhgfGmWdllMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/mailing-address-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change___cs___default",
    path: "/souhrn/profil/zmena-jmena",
    meta: indexNr3uwDyQyzMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/name-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-requested___cs___default",
    path: "/souhrn/profil/zmena-jmena/overeni",
    meta: requested0FNovJ1EoDMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/name-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-upload-documents___cs___default",
    path: "/souhrn/profil/zmena-jmena/nahrat-doklady",
    meta: upload_45documentsdKyYGv63ObMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/name-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change___cs___default",
    path: "/souhrn/profil/zmena-travale-adresy",
    meta: indexaQPzrCEeZ1Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/permanent-address-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-requested___cs___default",
    path: "/souhrn/profil/zmena-travale-adresy/overeni",
    meta: requested75AiATTBN4Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/permanent-address-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-upload-documents___cs___default",
    path: "/souhrn/profil/zmena-travale-adresy/nahrat-doklady",
    meta: upload_45documents9o7cM4HNTUMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/permanent-address-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: personal_45data_45confirmationX1URU47hmCMeta?.name,
    path: "personal-data-confirmation",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-personal-data-confirmation-additional-information___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/dalsi-informace",
    meta: additional_45informationnF3iC9S0w1Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation___cs___default",
    path: "/souhrn/profil/aktualizace-udaju",
    meta: indexKZPG7kVuBTMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/novy-dokument",
    meta: indexzkilT4fZ9RMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document-requested___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/novy-dokument/overeni",
    meta: requestedByOX7QJVAYMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba",
    meta: indexVSHXmSf0muMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person-requested___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba/overeni",
    meta: requested7XejZv2fKzMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu",
    meta: indexQrfe2syal0Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement-requested___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu/overeni",
    meta: requestedAseBJPCkiUMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/requested.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-profile-phone-change___cs___default",
    path: "/souhrn/profil/zmena-telefonu",
    meta: phone_45changeSWwqn2fdHwMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/phone-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security___cs___default",
    path: "/souhrn/profil/bezpecnost",
    meta: indexEAQ2hbPKAvMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/security/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security-password-change___cs___default",
    path: "/souhrn/profil/bezpecnost/zmena-hesla",
    meta: password_45changeqSqFP3cEOaMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/security/password-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-documents___cs___default",
    path: "/souhrn/profil/vypisy-a-dokumenty/dokumenty",
    meta: documents3HiAS8MQvIMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/statements-and-documents/documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents___cs___default",
    path: "/souhrn/profil/vypisy-a-dokumenty",
    meta: indexKYcq01F45TMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/statements-and-documents/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-statements-and-confirmations___cs___default",
    path: "/souhrn/profil/vypisy-a-dokumenty/vypisy-a-potvrzeni",
    meta: statements_45and_45confirmationsijKu9ycHPtMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/statements-and-documents/statements-and-confirmations.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileJvJJaCoDThMeta?.name,
    path: "/cs/dashboard/profile",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-add-account___cs",
    path: "/cs/souhrn/profil/pridat-ucet",
    meta: add_45accountCEVox3HlXPMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/add-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-additional-settings___cs",
    path: "/cs/souhrn/profil/dalsi-nastaveni",
    meta: additional_45settingsUMIjlufALNMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/additional-settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add___cs",
    path: "/cs/souhrn/profil/bankovni-ucty/pridat",
    meta: indexrpm8nhsrv0Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/bank-accounts/add/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-requested___cs",
    path: "/cs/souhrn/profil/bankovni-ucty/pridat/overeni",
    meta: requestedmmmKY3PbwYMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/bank-accounts/add/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-upload-document___cs",
    path: "/cs/souhrn/profil/bankovni-ucty/pridat/nahrat-doklad",
    meta: upload_45documentN4BZfpTPbIMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/bank-accounts/add/upload-document.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts___cs",
    path: "/cs/souhrn/profil/bankovni-ucty",
    meta: indexsa8YBA0qo1Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/bank-accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-delete-account___cs",
    path: "/cs/souhrn/profil/zruseni-uctu",
    meta: delete_45account5uygiNMkGpMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/delete-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update___cs",
    path: "/cs/souhrn/profil/aktualizace-dokladu",
    meta: indexPU9IIjqpiGMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/document-update/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-dokladu/overeni",
    meta: requestedKkxjSPBhmoMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/document-update/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-edit-personal-information___cs",
    path: "/cs/souhrn/profil/uprava-osobnich-udaju",
    meta: edit_45personal_45informationTN3lUa5gmoMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/edit-personal-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-change___cs",
    path: "/cs/souhrn/profil/zmena-e-mailu",
    meta: email_45changet9IpSdwQTyMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/email-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-preferences___cs",
    path: "/cs/souhrn/profil/e-mailova-sdeleni",
    meta: email_45preferenceswhCOo4vYxDMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/email-preferences.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification-czech-point___cs",
    path: "/cs/souhrn/profil/overeni-totoznosti/czech-point",
    meta: czech_45pointoMdzBtH43bMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/identity-verification/czech-point.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification___cs",
    path: "/cs/souhrn/profil/overeni-totoznosti",
    meta: indexjrIPQgA1oVMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___cs",
    path: "/cs/souhrn/profil",
    meta: indexF3vAibt6WoMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-mailing-address-change___cs",
    path: "/cs/souhrn/profil/zmena-korespondencni-adresy",
    meta: mailing_45address_45changeVhgfGmWdllMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/mailing-address-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change___cs",
    path: "/cs/souhrn/profil/zmena-jmena",
    meta: indexNr3uwDyQyzMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/name-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-requested___cs",
    path: "/cs/souhrn/profil/zmena-jmena/overeni",
    meta: requested0FNovJ1EoDMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/name-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-upload-documents___cs",
    path: "/cs/souhrn/profil/zmena-jmena/nahrat-doklady",
    meta: upload_45documentsdKyYGv63ObMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/name-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change___cs",
    path: "/cs/souhrn/profil/zmena-travale-adresy",
    meta: indexaQPzrCEeZ1Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/permanent-address-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-requested___cs",
    path: "/cs/souhrn/profil/zmena-travale-adresy/overeni",
    meta: requested75AiATTBN4Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/permanent-address-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-upload-documents___cs",
    path: "/cs/souhrn/profil/zmena-travale-adresy/nahrat-doklady",
    meta: upload_45documents9o7cM4HNTUMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/permanent-address-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: personal_45data_45confirmationX1URU47hmCMeta?.name,
    path: "personal-data-confirmation",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-personal-data-confirmation-additional-information___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/dalsi-informace",
    meta: additional_45informationnF3iC9S0w1Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju",
    meta: indexKZPG7kVuBTMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/novy-dokument",
    meta: indexzkilT4fZ9RMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/novy-dokument/overeni",
    meta: requestedByOX7QJVAYMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba",
    meta: indexVSHXmSf0muMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba/overeni",
    meta: requested7XejZv2fKzMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu",
    meta: indexQrfe2syal0Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu/overeni",
    meta: requestedAseBJPCkiUMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/requested.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-profile-phone-change___cs",
    path: "/cs/souhrn/profil/zmena-telefonu",
    meta: phone_45changeSWwqn2fdHwMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/phone-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security___cs",
    path: "/cs/souhrn/profil/bezpecnost",
    meta: indexEAQ2hbPKAvMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/security/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security-password-change___cs",
    path: "/cs/souhrn/profil/bezpecnost/zmena-hesla",
    meta: password_45changeqSqFP3cEOaMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/security/password-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-documents___cs",
    path: "/cs/souhrn/profil/vypisy-a-dokumenty/dokumenty",
    meta: documents3HiAS8MQvIMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/statements-and-documents/documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents___cs",
    path: "/cs/souhrn/profil/vypisy-a-dokumenty",
    meta: indexKYcq01F45TMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/statements-and-documents/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-statements-and-confirmations___cs",
    path: "/cs/souhrn/profil/vypisy-a-dokumenty/vypisy-a-potvrzeni",
    meta: statements_45and_45confirmationsijKu9ycHPtMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/dashboard/profile/statements-and-documents/statements-and-confirmations.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "element___cs___default",
    path: "/element",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/element.vue").then(m => m.default || m)
  },
  {
    name: "element___cs",
    path: "/cs/element",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/element.vue").then(m => m.default || m)
  },
  {
    name: "external-email-verification___cs___default",
    path: "/external/email-verification",
    meta: email_45verificationg3J2AXBwbDMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/external/email-verification.vue").then(m => m.default || m)
  },
  {
    name: "external-email-verification___cs",
    path: "/cs/external/email-verification",
    meta: email_45verificationg3J2AXBwbDMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/external/email-verification.vue").then(m => m.default || m)
  },
  {
    name: "external-impersonation___cs___default",
    path: "/external/impersonation",
    meta: impersonationwYypieJAicMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/external/impersonation.vue").then(m => m.default || m)
  },
  {
    name: "external-impersonation___cs",
    path: "/cs/external/impersonation",
    meta: impersonationwYypieJAicMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/external/impersonation.vue").then(m => m.default || m)
  },
  {
    name: "external-login___cs___default",
    path: "/external/login",
    meta: loginNWgcWuRhSsMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/external/login.vue").then(m => m.default || m)
  },
  {
    name: "external-login___cs",
    path: "/cs/external/login",
    meta: loginNWgcWuRhSsMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/external/login.vue").then(m => m.default || m)
  },
  {
    name: "external-password-reset___cs___default",
    path: "/external/password-reset",
    meta: password_45reset3onpIFcn98Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/external/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "external-password-reset___cs",
    path: "/cs/external/password-reset",
    meta: password_45reset3onpIFcn98Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/external/password-reset.vue").then(m => m.default || m)
  },
  {
    name: faqqdUhnRvVpXMeta?.name,
    path: "/faq",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/faq.vue").then(m => m.default || m),
    children: [
  {
    name: "faq-slug___cs___default",
    path: ":slug()",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq___cs___default",
    path: "",
    meta: index0At7MJA4tmMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/faq/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: faqqdUhnRvVpXMeta?.name,
    path: "/cs/faq",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/faq.vue").then(m => m.default || m),
    children: [
  {
    name: "faq-slug___cs",
    path: ":slug()",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq___cs",
    path: "",
    meta: index0At7MJA4tmMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/faq/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forgotten-password___cs___default",
    path: "/zapomenute-heslo",
    meta: forgotten_45passwords33Bj3xrqpMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/forgotten-password.vue").then(m => m.default || m)
  },
  {
    name: "forgotten-password___cs",
    path: "/cs/zapomenute-heslo",
    meta: forgotten_45passwords33Bj3xrqpMeta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/forgotten-password.vue").then(m => m.default || m)
  },
  {
    name: "how-does-it-work___cs___default",
    path: "/jak-to-funguje",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/how-does-it-work.vue").then(m => m.default || m)
  },
  {
    name: "how-does-it-work___cs",
    path: "/cs/jak-to-funguje",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/how-does-it-work.vue").then(m => m.default || m)
  },
  {
    name: "index___cs___default",
    path: "/",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/cs",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "investments-offer-slug___cs___default",
    path: "/nabidka-investic/:slug()",
    meta: _91slug_93Q9uOkcSWu0Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "investments-offer-slug___cs",
    path: "/cs/nabidka-investic/:slug()",
    meta: _91slug_93Q9uOkcSWu0Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "investments-offer___cs___default",
    path: "/nabidka-investic",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/investments-offer/index.vue").then(m => m.default || m)
  },
  {
    name: "investments-offer___cs",
    path: "/cs/nabidka-investic",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/investments-offer/index.vue").then(m => m.default || m)
  },
  {
    name: "login___cs___default",
    path: "/prihlasit-se",
    meta: login14D0mLbf75Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___cs",
    path: "/cs/prihlasit-se",
    meta: login14D0mLbf75Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "obligatory-published-information___cs___default",
    path: "/povinne-informace",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/obligatory-published-information.vue").then(m => m.default || m)
  },
  {
    name: "obligatory-published-information___cs",
    path: "/cs/povinne-informace",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/obligatory-published-information.vue").then(m => m.default || m)
  },
  {
    name: "privacy-settings___cs___default",
    path: "/nastaveni-soukromi",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/privacy-settings.vue").then(m => m.default || m)
  },
  {
    name: "privacy-settings___cs",
    path: "/cs/nastaveni-soukromi",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/privacy-settings.vue").then(m => m.default || m)
  },
  {
    name: "registration___cs___default",
    path: "/registrace",
    meta: registrationyVmAt6lJX6Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "registration___cs",
    path: "/cs/registrace",
    meta: registrationyVmAt6lJX6Meta || {},
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "security___cs___default",
    path: "/bezpecnost",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/security.vue").then(m => m.default || m)
  },
  {
    name: "security___cs",
    path: "/cs/bezpecnost",
    component: () => import("C:/FE02/_work/222/s/Gallery/pages/security.vue").then(m => m.default || m)
  }
]